require('./bootstrap');
function init() {
    window.addEventListener('scroll', function(e){
        let distanceY = window.pageYOffset || document.documentElement.scrollTop,
            shrinkOn = 30,
            headerLogo = document.getElementById("logo-img-on-header");
        if (window.scrollY <= 170 && document.getElementById('header_start_nav') && document.getElementById('header_start_nav')){
            document.getElementById('header_start_nav').classList.add('active');
        }
        if (distanceY > shrinkOn) {
            headerLogo.style['width']= '175px';
            headerLogo.style['height']= '90px';
        } else {
            headerLogo.style['width']= '230px';
            headerLogo.style['height']= '150px';
        }
    });
    if ($('#contact_form').length) {
        initContactForm();
    }
    $(".contact-nav").on('click', function (e){
        e.preventDefault();
        let section = $(this).attr("href");
        if(section.includes('#contact_form_wrapper')){
            $("html, body").animate({
                scrollTop: $(section).offset().top
            }, 700);
        }
    });
    $(".navbar-nav a:not('.dropdown-toggle')").on('click', function () { $('.navbar-collapse').collapse('hide'); });
    if($('#navbar-scroll-to-sections').length) {
        $('#navbar-scroll-to-sections a[href^=\'#\']').on('click', function (e){
            e.preventDefault();
            let hash = this.hash;
            // animate
            $('html, body').animate({
                scrollTop: $(hash).offset().top
            }, 700, function(){
                window.location.hash = hash;
            });
        });
    }
    if($('#footer-nav').length) {
        $('#footer-nav a[href^=\'#\']').on('click', function (e){
            e.preventDefault();
            let hash = this.hash;
            // animate
            $('html, body').animate({
                scrollTop: $(hash).offset().top
            }, 700, function(){
                window.location.hash = hash;
            });
        });
    }
}

function initContactForm(){
    const contactForm = $('#contact_form');
    contactForm.on('submit', function (e){
        e.preventDefault();
        e.stopPropagation();
        $('.message-general-fail-form').addClass('d-none');
        $('.message-success-form').addClass('d-none');
        let emailInput = $("#email_input").val();
        if(validateEmail(emailInput)){

            let data = contactForm.serialize();
            let route = contactForm.attr('action');
            $.ajax({
                url: route,
                method: 'POST',
                data: data,
                dataType: 'json',
            }).done(function (response){
                // console.log('response is', response);
                if(response && response.success){
                    contactForm.hide('slow');
                    $('.message-success-form').removeClass('d-none');
                } else{
                    $('.message-general-fail-form').removeClass('d-none');
                }
            }).fail(function (jqXHR) {
                // console.log('error ajax:', jqXHR);
                // console.log(jqXHR.responseJSON.errors);
                $('.message-general-fail-form').removeClass('d-none');
                showErrors(jqXHR.responseJSON.errors);
            });
        } else {
            $('[id="' + 'email_input' + '"]').addClass('is-invalid');
            $('.message-general-fail-form').removeClass('d-none');
        }
    })
}
function validateEmail(email){
    let emailChar = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    return emailChar.test(email);
}
function showErrors(errors) {
    if(errors){
        $('.invalid-feedback').empty();
        $('.form-control').removeClass('is-invalid');
        $('.invalid-feedback-recapcha').addClass('d-none');
        let errorsObject = Object.entries(errors);
        for (let [key, value] of errorsObject) {
            console.log('key and value', key, value);
            switch(key) {
                case 'g-recaptcha-response':

                    $('.invalid-feedback-recapcha').removeClass('d-none').text(value);
                    break;
                default:

                    $('[id="' + key + '"]').addClass('is-invalid');
                    $('[id="' + key + '"] + .invalid-feedback').text(value);
            }
        }
    }
}
window.onload = init();
